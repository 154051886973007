<template>
  <div id="AboutPage">
    <dynamic-title title="AdaCalculator.com - About"></dynamic-title>
    
    <div class="AboutPage__Top PageMain__Top">
      <site-header></site-header>

      <div class="AboutEntry">
        <h3 class="AboutEntry__Header">
          <span class="AboutEntry__Title solid-heading">Cardano ADA Staking Pool</span>
        </h3>
        <p class="AboutEntry__Body solid-background">
          If you are looking for a Cardano staking pool, we will run the following pool which we welcome you to join: <a href="https://adastaking.com/" class="Util-bold">AdaStaking.com</a>
        </p>
      </div>

      <div class="AboutEntry">
        <h3 class="AboutEntry__Header">
          <span class="AboutEntry__Title solid-heading">About Ada Calculator:</span>
        </h3>
        <p class="AboutEntry__Body solid-background">
          AdaCalculator.com has a simple aim: To satisfy your Cardano ADA cryptocurrency calculation needs whilst providing a clean design and user experience. The site is a work in progress built to serve the Cardano community. We aim to continue adding new, useful features to the calculators in the future, so don't forget to check back soon!
        </p>
      </div>

      <div class="AboutEntry">
       <p class="About__CalcList solid-background">
          The current incarnation of the site includes two calculators: 
          <ol>
            <li>ADA Price Conversion Calculator</li>
            <li>Cardano ADA Staking Calculator</li>
          </ol>
        </p>
      </div>

      <div class="AboutEntry">
        <p class="solid-background">
          <span class="Util-bold">1) Price Conversion Calculator</span>
          <br>Displays the latest price of ADA supporting convertions from ADA into Fiat or other Crytpocurrencies such as Bitcoin and Ethereum.
          The price calculator pulls the price data from the public CoinGecko.com API every 4 seconds keeping you up to date.
        </p>
      </div>

      <div class="AboutEntry">
        <p class="solid-background">
          <span class="Util-bold">2) Cardano ADA Staking Calculator</span>
          <br>The Cardano project has yet to announce the offical specification and formulas which will be used to calculate ADA staking rewards. The calculator we currently present is therefore a demonstrative/educational calculator only, which fills in the currently unknown with sensible default parameter values aiming to provide a decent attempt at what ADA rewards one could possibly expect. 
          <br>Once Cardano release the final details regarding how staking works, we will update the staking calculator to reflect the final formula, providing greater accuracy.
          <br>The calculator, features, and formula used was heavily inpired by the work from Emergo employee and Cardano extraordinaire:
          <br><span class="Util-bold">vantuz-subhuman</span>, and his <a href="https://antipalos.github.io/cardano-calculator/#formula">open-source project</a>.
        </p>
      </div>

      <div class="AboutEntry">
        <h3 class="AboutEntry__Header">
          <span class="AboutEntry__Title solid-heading">How the site works:</span>
        </h3>
        <p class="AboutEntry__Body solid-background">
          None of the data you enter on any of the calculators ever leaves your computer as all calculations are performed on the client side (inside your browser). The persistant state of what you have entered is stored using the browsers 'localStorage' which is only available to your machine and not to us.
        </p>
      </div>

      <!-- <div class="AboutEntry">
        <h3 class="AboutEntry__Header">
          <span class="AboutEntry__Title solid-heading">Geek Info:</span>
        </h3>
        <p class="AboutEntry__Body solid-background">
          For those interested, this site was built using a combination of the following web technologies:
          <br>VueJs, VueRouter, Vuex, SASS, nginx and Node
        </p>
      </div> -->


          <!-- <p class="solid-background">AdaCalculator.com is a simple site built to satisfy your Cardano ADA cryptocurrency calculation needs.<br>
      This includes showing you the latest price of ADA and convertions into Fiat and Crytpocurrencies.<br>
      We will be creating a ADA Cardano staking calculator when the project releases the staking reward structure.<br>
      If you are looking for a Cardano staking pool, we run the following pool and you are welcome to join us: <a href="https://adastaking.com/">AdaStaking.com</a></p>

          <p class="solid-background">AdaCalculator.com is a simple site built to satisfy your Cardano ADA cryptocurrency calculation needs.<br>
      This includes showing you the latest price of ADA and convertions into Fiat and Crytpocurrencies.<br>
      We will be creating a ADA Cardano staking calculator when the project releases the staking reward structure.<br>
      If you are looking for a Cardano staking pool, we run the following pool and you are welcome to join us: <a href="https://adastaking.com/">AdaStaking.com</a></p> -->

    </div> <!-- End .AboutPage__Top PageMain__Top -->
  </div> <!-- End #AboutPage-->
</template>

<script>
/* 
 * @ is an alias to /src EG:
 * import HelloWorld from '@/components/Price/HelloWorld.vue';
 */


// import { mapState } from 'vuex'
// import { mapGetters } from 'vuex'
// import { mapMutations } from 'vuex'
// import { mapActions } from 'vuex'

export default {

  computed: {
  },

  methods: {
  },
};
</script>